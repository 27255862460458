@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}

@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 0 0 transparent;
  }
  100% {
    box-shadow: inset 0 0 14px 0 rgb(0 0 0 / 50%);
  }
}
