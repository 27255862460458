*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-\[94px\] {
  bottom: 94px;
}

.left-\[calc\(50\%_-_224px\)\] {
  left: calc(50% - 224px);
}

.top-\[210\.73px\] {
  top: 210.73px;
}

.left-\[calc\(50\%_-_892\.27px\)\] {
  left: calc(50% - 892.27px);
}

.top-\[837\.12px\] {
  top: 837.12px;
}

.left-\[287px\] {
  left: 287px;
}

.top-\[0px\] {
  top: 0;
}

.left-\[0px\] {
  left: 0;
}

.top-\[73px\] {
  top: 73px;
}

.top-\[660px\] {
  top: 660px;
}

.left-\[1627px\] {
  left: 1627px;
}

.top-\[625px\] {
  top: 625px;
}

.top-\[781px\] {
  top: 781px;
}

.top-\[95px\] {
  top: 95px;
}

.left-\[calc\(50\%_-_364px\)\] {
  left: calc(50% - 364px);
}

.top-\[1126px\] {
  top: 1126px;
}

.left-\[250px\] {
  left: 250px;
}

.top-\[57\.07px\] {
  top: 57.07px;
}

.left-\[57\.07px\] {
  left: 57.07px;
}

.top-\[53px\] {
  top: 53px;
}

.top-\[34\.18px\] {
  top: 34.18px;
}

.left-\[33\.93px\] {
  left: 33.93px;
}

.top-\[-57\.07px\] {
  top: -57.07px;
}

.right-\[-57\.07px\] {
  right: -57.07px;
}

.top-\[496\.82px\] {
  top: 496.82px;
}

.left-\[472\.93px\] {
  left: 472.93px;
}

.top-\[45\.54px\] {
  top: 45.54px;
}

.top-\[1954px\] {
  top: 1954px;
}

.right-\[395\.38px\] {
  right: 395.38px;
}

.top-\[499\.75px\] {
  top: 499.75px;
}

.left-\[-0\.07px\] {
  left: -.07px;
}

.top-\[-3\.07px\] {
  top: -3.07px;
}

.left-\[872\.93px\] {
  left: 872.93px;
}

.top-\[313\.93px\] {
  top: 313.93px;
}

.top-\[2829\.5px\] {
  top: 2829.5px;
}

.top-\[2887px\] {
  top: 2887px;
}

.top-\[4124px\] {
  top: 4124px;
}

.left-\[815px\] {
  left: 815px;
}

.top-\[4025px\] {
  top: 4025px;
}

.left-\[912px\] {
  left: 912px;
}

.top-\[3174px\] {
  top: 3174px;
}

.left-\[605px\] {
  left: 605px;
}

.top-\[0\%\] {
  top: 0%;
}

.right-\[0\%\] {
  right: 0%;
}

.bottom-\[0\%\] {
  bottom: 0%;
}

.left-\[0\%\] {
  left: 0%;
}

.left-\[842px\] {
  left: 842px;
}

.left-\[1335px\] {
  left: 1335px;
}

.m-\[0\] {
  margin: 0;
}

.m-8 {
  margin: 2rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.box-border {
  box-sizing: border-box;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-0\.5 {
  height: .125rem;
}

.h-0 {
  height: 0;
}

.h-\[4505px\] {
  height: 4505px;
}

.h-\[32\.48px\] {
  height: 32.48px;
}

.h-\[1030\.55px\] {
  height: 1030.55px;
}

.h-\[139px\] {
  height: 139px;
}

.h-\[106px\] {
  height: 106px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[761px\] {
  height: 761px;
}

.h-\[647px\] {
  height: 647px;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[104px\] {
  height: 104px;
}

.h-\[330px\] {
  height: 330px;
}

.h-\[262px\] {
  height: 262px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[608\.69px\] {
  height: 608.69px;
}

.h-\[144\.21px\] {
  height: 144.21px;
}

.h-\[98\.67px\] {
  height: 98.67px;
}

.h-\[611\.62px\] {
  height: 611.62px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[49px\] {
  height: 49px;
}

.h-\[15\.63px\] {
  height: 15.63px;
}

.h-\[22\.58px\] {
  height: 22.58px;
}

.h-full {
  height: 100%;
}

.h-\[26\.05px\] {
  height: 26.05px;
}

.w-16 {
  width: 4rem;
}

.w-full {
  width: 100%;
}

.w-\[31px\] {
  width: 31px;
}

.w-\[1784\.88px\] {
  width: 1784.88px;
}

.w-\[770px\] {
  width: 770px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[727px\] {
  width: 727px;
}

.w-\[1420\.15px\] {
  width: 1420.15px;
}

.w-\[1306px\] {
  width: 1306px;
}

.w-\[414px\] {
  width: 414px;
}

.w-\[433px\] {
  width: 433px;
}

.w-\[365px\] {
  width: 365px;
}

.w-\[75\.82px\] {
  width: 75.82px;
}

.w-\[967\.69px\] {
  width: 967.69px;
}

.w-\[392\.78px\] {
  width: 392.78px;
}

.w-\[1420px\] {
  width: 1420px;
}

.w-\[96px\] {
  width: 96px;
}

.w-\[710px\] {
  width: 710px;
}

.w-\[297\.44px\] {
  width: 297.44px;
}

.w-\[15\.63px\] {
  width: 15.63px;
}

.w-\[613\.99px\] {
  width: 613.99px;
}

.w-\[48\.63px\] {
  width: 48.63px;
}

.w-\[22\.58px\] {
  width: 22.58px;
}

.w-\[26\.05px\] {
  width: 26.05px;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-\[40px\] {
  gap: 40px;
}

.gap-\[15px\] {
  gap: 15px;
}

.gap-\[0px\] {
  gap: 0;
}

.gap-\[165px\] {
  gap: 165px;
}

.gap-\[25px\] {
  gap: 25px;
}

.gap-\[47px\] {
  gap: 47px;
}

.gap-\[19\.11px\] {
  gap: 19.11px;
}

.gap-\[6\.95px\] {
  gap: 6.95px;
}

.gap-\[7\.82px\] {
  gap: 7.82px;
}

.gap-\[5\.21px\] {
  gap: 5.21px;
}

.gap-\[8\.68px\] {
  gap: 8.68px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-\[35px\] {
  border-radius: 35px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[17\.5px\] {
  border-radius: 17.5px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[28\.92px\] {
  border-radius: 28.92px;
}

.rounded-\[6\.95px\] {
  border-radius: 6.95px;
}

.rounded-\[4\.34px\] {
  border-radius: 4.34px;
}

.border-0 {
  border-width: 0;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(247 246 210 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 252 / var(--tw-bg-opacity));
}

.bg-violet {
  background-color: #a3adff24;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-\[transparent\] {
  background-color: #0000;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[30px_40px\] {
  padding: 30px 40px;
}

.p-\[15px\] {
  padding: 15px;
}

.p-\[45\.5128173828125px\] {
  padding: 45.5128px;
}

.p-\[3\.473789691925049px_0px_36\.47479248046875px\] {
  padding: 3.47379px 0 36.4748px;
}

.p-\[15\.63205337524414px_22\.579633712768555px\] {
  padding: 15.6321px 22.5796px;
}

.p-\[8\.684473991394043px_8\.684473991394043px_8\.684473991394043px_0px\] {
  padding: 8.68447px 8.68447px 8.68447px 0;
}

.p-\[4\.3422369956970215px_0px\] {
  padding: 4.34224px 0;
}

.p-\[15\.63205337524414px_36\.47479248046875px\] {
  padding: 15.6321px 36.4748px;
}

.pt-4 {
  padding-top: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-inter {
  font-family: Inter;
}

.font-roboto {
  font-family: Roboto;
}

.text-2xl {
  font-size: 33px;
}

.text-4xl {
  font-size: 35.27px;
}

.text-lg {
  font-size: 23px;
}

.text-sm {
  font-size: 20px;
}

.text-xs {
  font-size: 17px;
}

.text-3xl {
  font-size: 33.46px;
}

.text-base {
  font-size: 21.82px;
}

.text-xl {
  font-size: 25.72px;
}

.text-\[65\.88px\] {
  font-size: 65.88px;
}

.text-\[54\.64px\] {
  font-size: 54.64px;
}

.text-\[72\.4px\] {
  font-size: 72.4px;
}

.text-\[36\.97px\] {
  font-size: 36.97px;
}

.text-\[27\.79px\] {
  font-size: 27.79px;
}

.text-3xs {
  font-size: 14.76px;
}

.text-4xs {
  font-size: 13.9px;
}

.text-2xs {
  font-size: 16px;
}

.text-\[19\.11px\] {
  font-size: 19.11px;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-extrabold {
  font-weight: 800;
}

.leading-\[112\.02\%\] {
  line-height: 112.02%;
}

.leading-\[135\.02\%\] {
  line-height: 135.02%;
}

.leading-\[111\.02\%\] {
  line-height: 111.02%;
}

.leading-\[109\.52\%\] {
  line-height: 109.52%;
}

.leading-\[130\%\] {
  line-height: 130%;
}

.leading-\[150\%\] {
  line-height: 150%;
}

.tracking-\[-0\.04em\] {
  letter-spacing: -.04em;
}

.tracking-\[-0\.03em\] {
  letter-spacing: -.03em;
}

.tracking-\[-0\.05em\] {
  letter-spacing: -.05em;
}

.tracking-\[-0\.01em\] {
  letter-spacing: -.01em;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(17 17 17 / var(--tw-text-opacity));
}

.text-gray-1000 {
  color: #11111175;
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(99 97 31 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(159 156 87 / var(--tw-text-opacity));
}

.text-gray-1100 {
  color: #111111ad;
}

.text-gray-1200 {
  color: #1111119e;
}

.text-gray-1300 {
  color: #111111a8;
}

.text-gray-1400 {
  color: #00000052;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(90 108 255 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-\[inherit\] {
  color: inherit;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(13 13 13 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(72 72 72 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(77 77 77 / var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(5 214 123 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.opacity-\[1\] {
  opacity: 1;
}

.shadow-\[0px_0\.8684474229812622px_3\.47px_\#c9beff\,_0px_0\.8684474229812622px_2\.61px_\#c9beff\] {
  --tw-shadow: 0px .868447px 3.47px #c9beff, 0px .868447px 2.61px #c9beff;
  --tw-shadow-colored: 0px .868447px 3.47px var(--tw-shadow-color), 0px .868447px 2.61px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[margin-block-start\:0\] {
  margin-block-start: 0;
}

.\[margin-block-end\:0px\] {
  margin-block-end: 0;
}

.\[border\:2\.3px_solid_rgba\(184\,_192\,_255\,_0\.4\)\] {
  border: 2.3px solid #b8c0ff66;
}

.\[border\:1px_solid_rgba\(0\,_0\,_0\,_0\.1\)\] {
  border: 1px solid #0000001a;
}

.\[border\:1px_solid_rgba\(0\,_0\,_0\,_0\.14\)\] {
  border: 1px solid #00000024;
}

.\[border\:1px_solid_rgba\(163\,_173\,_255\,_0\.25\)\] {
  border: 1px solid #a3adff40;
}

.\[text-decoration\:none\] {
  text-decoration: none;
}

.\[border\:1\.1px_solid_rgba\(0\,_0\,_0\,_0\.1\)\] {
  border: 1.1px solid #0000001a;
}

.\[border\:none\] {
  border: none;
}

.\[border\:1\.3px_solid_\#111\] {
  border: 1.3px solid #111;
}

body {
  margin: 0;
  line-height: normal;
}

@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 #0000;
  }

  100% {
    box-shadow: inset 0 0 14px #00000080;
  }
}

.active\:animate-\[1s_ease_0s_infinite_normal_none_shadow-inset-center\]:active {
  animation: 1s infinite shadow-inset-center;
}

.active\:opacity-\[1\]:active {
  opacity: 1;
}

@media (min-width: 640px) {
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (min-width: 768px) {
  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:mt-\[0\] {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:m-\[0\] {
    margin: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:text-xl {
    font-size: 25.72px;
  }
}

@media (min-width: 1280px) {
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

.\[\&\.animate\]\:animate-\[1s_ease_0s_1_normal_forwards_shadow-inset-center\].animate {
  animation: 1s forwards shadow-inset-center;
}

/*# sourceMappingURL=index.a6d2c3f4.css.map */
